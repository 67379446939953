import React from 'react'

const REGEX =
  /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function uuidValidate(uuid) {
  return typeof uuid === 'string' && REGEX.test(uuid)
}

export const hideOrShowProperty = (discover, property, type) => {
  if (discover && Object.keys(discover).length > 0) {
    if (!discover.metadata.visibility[type][property]) {
      if (
        discover.metadata.visibility[type][property] === undefined ||
        discover.metadata.visibility[type][property] === null
      ) {
        return true
      }
    }
    return !!discover.metadata.visibility[type][property]
  } else {
    return true
  }
}
export function getIngredientsTemps(
  category,
  arrayIngredientsCategory,
  arrayToFilter,
  mapIngredientCode,
  showCodeObj,
  type,
  t
) {
  function handleTranslation(value) {
    if (t) {
      return t(`${type}.${value}`)
    }
    return value
  }
  //const regex = /E\d{3,4}[a-z]{0,18}/g

  /* function handleTranslation(value) {
    return t(`${type}.${value}`)
  } */

  const allergenes = [
    'lysozyme',
    'potassiumBisulphite',
    'sulphurDioxide2',
    'potassiumMetabisulphite',
    'sulphites',
    'lysozyme2',
    'potassiumBisulphite2',
    'sulphurDioxide3',
    'potassiumMetabisulphite2',
    'sulphites2'
  ]

  let ingredientsTemp = arrayToFilter.filter((el) =>
    arrayIngredientsCategory.includes(el)
  )

  let showCode = false
  if (showCodeObj && Object.keys(showCodeObj).length) {
    showCode = showCodeObj[category]
  }

  if (showCode) {
    const arrayMatch = ingredientsTemp.map((value) => {
      const result = mapIngredientCode[value]
        ? mapIngredientCode[value]
        : handleTranslation(value)

      return allergenes.includes(value) ? <b key={value}>{result}</b> : result
    })

    ingredientsTemp = arrayMatch
  } else {
    ingredientsTemp = ingredientsTemp.map((x, index) => {
      return allergenes.includes(x) ? (
        <b key={index}>{handleTranslation(x)}</b>
      ) : (
        handleTranslation(x)
      )
    })
  }

  const andOrCategory = [
    'acidityRegulators',
    'agentsStabilising',
    'aromatic_acidityRegulators',
    'aromatic_agentsStabilising'
  ]
  const separator = andOrCategory.includes(category) ? ` ${t('andOr')} ` : ', '

  // Join the ingredients with a comma and space, removing the last comma
  ingredientsTemp = ingredientsTemp.reduce((prev, curr, index) => {
    return index !== ingredientsTemp.length - 1
      ? [...prev, curr, separator]
      : [...prev, curr]
  }, [])

  //ingredientsTemp.pop() // Remove the last comma

  return ingredientsTemp
}
